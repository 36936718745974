import { robotsNavItems } from "../components/Gamification/constants";

export const navItems = [
  {
    name: "Информация о приложении",
    link: "edit",
  },
  {
    name: "Уведомления",
    link: "notifications",
  },
  {
    name: "Главные страницы приложения",
    link: "pages",
  },
  {
    name: "Версии",
    link: "versions",
  },
  {
    name: "Опросы",
    link: "polls",
  },
  {
    name: "Награды",
    link: "achievements",
  },
  ...robotsNavItems,
  {
    name: "Marketing Modals",
    link: "marketing-modals",
  }
];

export const navItemsMobileAppsMainPages = [
  {
    name: "Главная",
    link: "edit",
  },
  {
    name: "Список моделей",
    link: "models",
  },
  {
    name: "уроки",
    link: "lessons",
  },
];
