import { useState } from "react";
import { Table } from "@material-ui/core";
import { MarketingModalsListItem, MarketingModalsTableHeader } from "./style";
import { MainTableCell, MainTableHeaderTitle } from "../shared/Style/Style";
import { ContainerWithNavBar } from "../Gamification/shared/ContainerWithNavBar";
import { EditMarketingModal } from "./EditMarketingModal";
import { AppTypeNameEnum, MarketingModalInfoType, useGetMarketingModalsQuery } from "../../generated/graphql";

interface EditModalStateProps {
    isOpen: boolean;
    type: MarketingModalInfoType;
    appTypeName: AppTypeNameEnum;
}

const INITIAL_EDIT_MODAL_STATE: EditModalStateProps = {
    isOpen: false,
    type: null,
    appTypeName: null,
};
const marketingModalsHeader = ["Название", "Тип", "Устройство"];

export const MarketingModalsInfo = () => {
    const [openEditModal, setOpenEditModal] = useState(INITIAL_EDIT_MODAL_STATE);

    const { data } = useGetMarketingModalsQuery();
    const marketingModalsList = data?.getMarketingModals;
    
    const openEditModalHandler = (
        type: MarketingModalInfoType,
        appTypeName: AppTypeNameEnum
    ) => { 
        setOpenEditModal({
            isOpen: true,
            type,
            appTypeName,
        });
    };
 
    return (
        <ContainerWithNavBar header="marketing modals">
            <Table>
                <MarketingModalsTableHeader>
                    {marketingModalsHeader.map((item) => (
                        <MainTableHeaderTitle key={item}>{item}</MainTableHeaderTitle>
                    ))}
                </MarketingModalsTableHeader>
            </Table>

            <>
                {marketingModalsList?.map((modalInfo) => (
                    <MarketingModalsListItem
                        key={modalInfo.id}
                        onClick={() => openEditModalHandler(modalInfo.type, modalInfo.appType?.name)}
                    >
                    <MainTableCell>{modalInfo.title}</MainTableCell>
                    <MainTableCell>{modalInfo.type}</MainTableCell>
                    <MainTableCell>{modalInfo.appType?.name}</MainTableCell>
                    </MarketingModalsListItem>
                ))}
            </>

            {openEditModal.isOpen &&
                <EditMarketingModal
                    type={openEditModal.type}
                    appType={openEditModal.appTypeName}
                    onClose={() => setOpenEditModal(INITIAL_EDIT_MODAL_STATE)}
                />
            }

        </ContainerWithNavBar>
    );
};
