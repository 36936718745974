import { FC, useEffect, useRef, useState } from "react";
import { Dialog, Box, DialogTitle, IconButton } from "@material-ui/core";
import ClearIcon from '@material-ui/icons/Clear';
import { CloseIconButton } from "../shared/Buttons/CloseIconButton";
import { LoadingProgressOverlay } from "../shared/LoadingProgress";
import { BundleLink as MediaFileLink, ButtonCustom, ButtonsWrapper, CancelButton, ModalInput, ImportFileUpload } from "../shared/Style/Style";
import { MediaFileLinkContainer } from "./style";
import { AppTypeNameEnum, GetMarketingModalInfoDocument, GetMarketingModalsDocument, MarketingModalInfoType, useGetMarketingModalInfoQuery, useUpdateMarketingMediaFileMutation, useUpdateMarketingModalInfoMutation } from "../../generated/graphql";

interface EditMarketingModalProps {
    type: MarketingModalInfoType;
    appType: AppTypeNameEnum;
    onClose: () => void;
}

export const EditMarketingModal: FC<EditMarketingModalProps> = ({
    type,
    appType,
    onClose,
}) => {
    const mediaFileInput = useRef<HTMLInputElement | null>(null);
    const [mediaFileLink, setMediaFileLink] = useState<string | null>(null);
    
    const { data: modalInfoData, loading: modalInfoLoading } = useGetMarketingModalInfoQuery({
        variables: {
            type,
            appType,
        },
    });
    const [updateMediaFile, { loading: updateMediaFileLoading }] = useUpdateMarketingMediaFileMutation();
    const [updateModalInfo, { loading: updateModalInfoLoading}] = useUpdateMarketingModalInfoMutation();

    const isLoading = modalInfoLoading || updateMediaFileLoading || updateModalInfoLoading;

    useEffect(() => {
        if (modalInfoData?.getMarketingModalInfo?.fileLink) {
            setMediaFileLink(modalInfoData.getMarketingModalInfo.fileLink);
        }
    }, [modalInfoData]);

    const uploadMediaFile = (file: File) => {
        if (!file) return;
        const reader = new FileReader();
        reader.onload = () => {
            updateMediaFile({
                variables: {
                    data: {
                        file,
                        appType,
                        type
                    },
                },
                onCompleted: (data) => {
                    if (data?.updateMarketingModalInfo?.fileLink) { 
                        setMediaFileLink(data.updateMarketingModalInfo.fileLink);
                    }
                },
            });
        };
        reader.readAsDataURL(file);
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const formData = new FormData(e.target as HTMLFormElement);      
        const formValues = {
            title: formData.get('title'),
            subTitle: formData.get('subTitle'),
            features: (formData.get('featuresList') as string)?.split("\n") || [],
            note: formData.get('note'),
        };

        await updateModalInfo({
            variables: {
                data: {
                    type,
                    appType,
                    title: formValues.title as string,
                    subTitle: formValues.subTitle as string,
                    features: formValues.features as string[],
                    note: formValues.note as string,
                    fileLink: mediaFileLink,
                },
            },
            awaitRefetchQueries: true,
            refetchQueries: [GetMarketingModalsDocument, GetMarketingModalInfoDocument],
        });
        onClose();
    };

    if (!modalInfoData?.getMarketingModalInfo) return null;

    return (
        <Dialog open maxWidth="md">
            <DialogTitle>Редактирование</DialogTitle>
            <Box px={5} pb={5} width={580}>
                {isLoading && <LoadingProgressOverlay />}
                <CloseIconButton close={onClose} />
                <form onSubmit={handleSubmit}>
                    <ModalInput
                        key="title"
                        name="title"
                        type="string"
                        variant="filled"
                        margin="normal"
                        label="Заголовок"
                        defaultValue={modalInfoData.getMarketingModalInfo.title || null}
                    />
                    <ModalInput
                        key="type"
                        name="type"
                        type="string"
                        variant="filled"
                        margin="normal"
                        label="Тип"
                        value={type}
                        disabled
                    />
                    <ModalInput
                        key="appType"
                        name="appType"
                        type="string"
                        variant="filled"
                        margin="normal"
                        label="Устройство"
                        value={appType}
                        disabled
                    />
                    <ModalInput
                        key="subTitle"
                        name="subTitle"
                        type="string"
                        variant="filled"
                        margin="normal"
                        multiline
                        label="Подзаголовок"
                        defaultValue={modalInfoData.getMarketingModalInfo.subTitle || null}
                    />
                    <ModalInput
                        key="featuresList"
                        name="featuresList"
                        type="string"
                        variant="filled"
                        margin="normal"
                        multiline
                        label="Список преимуществ (указывать с новой строки):"
                        defaultValue={modalInfoData.getMarketingModalInfo.features?.map(feature => feature.description)?.join('\n') || null}
                    />
                    <ModalInput
                        key="note"
                        name="note"
                        type="string"
                        variant="filled"
                        margin="normal"
                        label="Примечание"
                        defaultValue={modalInfoData.getMarketingModalInfo.note || null}
                    />
                    <MediaFileLinkContainer>
                        {mediaFileLink ? (
                            <>
                                <MediaFileLink href={mediaFileLink} target="_blank">
                                    Ссылка на медиа файл
                                </MediaFileLink>

                                <IconButton
                                    disabled={isLoading}
                                    onClick={() => setMediaFileLink(null)}
                                >
                                    <ClearIcon color="error" fontSize="small" />
                                </IconButton>
                            </>
                        ) : (
                                <ButtonCustom
                                    disabled={isLoading}
                                    onClick={() => mediaFileInput.current.click()}
                                >
                                    <span>Прикрепить файл</span>
                                    <ImportFileUpload
                                        type="file"
                                        ref={mediaFileInput}
                                        onChange={({ target: { files } }) =>
                                            files[0] && uploadMediaFile(files[0])}
                                    />
                                </ButtonCustom>                         
                            )
                        }
                    </MediaFileLinkContainer>
                    <ButtonsWrapper>
                        <CancelButton
                            disabled={isLoading}
                            onClick={onClose}
                        >
                            Отменить
                        </CancelButton>

                        <ButtonCustom
                            type="submit"
                            disabled={isLoading}
                        >
                            Сохранить
                        </ButtonCustom>
                    </ButtonsWrapper>
                </form>
            </Box>
        </Dialog>
    );  
};
