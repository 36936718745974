import styled from "styled-components";
import { Box } from "@material-ui/core";
import { MainTableRow, primary } from "../shared/Style/Style";

export const MarketingModalsTableHeader = styled(MainTableRow)`
  grid-template-columns: 1.4fr 0.8fr 0.8fr;
  grid-gap: 10px;
  align-items: center;
  justify-content: start;
`;

export const MarketingModalsListItem = styled(MarketingModalsTableHeader)`
  min-height: 80px;
  cursor: pointer;

    &:hover {
        text-decoration-color: ${primary};
    }
`;

export const MediaFileLinkContainer = styled(Box)`
  display: flex;
  justify-content: end;
  align-items: center;
  margin-top: 20px;
`;
